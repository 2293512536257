// extracted by mini-css-extract-plugin
export var buttonWrapper = "herosection-module--button-wrapper--fgHjQ";
export var callToActionBtn = "herosection-module--call-to-action-btn--jxKTY";
export var content = "herosection-module--content--ZO8Cy";
export var contentWrapper = "herosection-module--content-wrapper--XcXr8";
export var imgWrapper = "herosection-module--img-wrapper--A04eg";
export var mark = "herosection-module--mark--c1gIh";
export var secondaryActionBtn = "herosection-module--secondary-action-btn--cHVls";
export var subtitle = "herosection-module--subtitle--4Sz-N";
export var textWrapper = "herosection-module--text-wrapper--PF8+r";
export var title = "herosection-module--title--b55FQ";
export var wrapper = "herosection-module--wrapper--t--vl";