import React from "react";
import type { HeadFC } from "gatsby";
import Layout from "@/components/Layout";
import Advice from "@/components/advice/Advice";
import HeroSection from "@/components/advice/HeroSection";
import Achievements from "@/components/Achievements";
import ContactForm from "@/components/ContactForm";
import completePackageImg from "@/images/complete-package.webp";
import { GTM } from "@/components/GTM";

const IndexPage = () => {
  return (
    <Layout hero={<HeroSection />}>
      <main>
        <Advice />
        <Achievements />
        <ContactForm />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <GTM />
    <title>Advice | Multiply</title>
    <meta
      name="description"
      content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
    ></meta>
    <meta
      name="og:description"
      content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
    ></meta>
    <meta name="image" content={completePackageImg}></meta>
    <meta name="og:image" content={completePackageImg}></meta>
    <link rel="canonical" href="https://www.multiply.ai/" />
    <link rel="canonical" href="https://blog.multiply.ai/" />
  </>
);
